import { signIn } from '@astro-auth/client'
import type { FormEvent } from 'react'

import Logo from '@/assets/logo.webp'

export const FormSignIn = () => {
  const showRecoverPassword = false
  const showRegisterAccount = false

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const formData = new FormData(e.target as HTMLFormElement)
    const data = Object.fromEntries(formData)

    await signIn({
      provider: 'credential',
      login: data,
    })
    window.location.href = '/'
  }

  return (
    <div className='pt:mt-0 mx-auto flex w-full flex-col items-center justify-center px-6 pt-8 dark:bg-gray-900'>
      <a
        href='#'
        className='mb-8 flex items-center justify-center text-2xl font-semibold dark:text-white lg:mb-10'
      >
        <img src={Logo} alt='FlowBite Logo' className='h-24' />
      </a>
      <div className='w-full max-w-xl space-y-8 rounded-lg bg-white p-6 shadow dark:bg-gray-800 sm:p-8'>
        <h2 className='text-2xl font-bold text-gray-900 dark:text-white'>
          Acceso a tu cuenta
        </h2>
        <form className='mt-8 space-y-6' onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor='email'
              className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
            >
              Correo electrónico
            </label>
            <input
              type='email'
              name='email'
              id='email'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm'
              placeholder='ejemplo@humanaitech.com'
              required
            />
          </div>
          <div>
            <label
              htmlFor='password'
              className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
            >
              Contraseña
            </label>
            <input
              type='password'
              name='password'
              id='password'
              placeholder='••••••••'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 sm:text-sm'
              required
            />
          </div>
          {showRecoverPassword && (
            <div className='flex flex-wrap items-start'>
              <div className='flex h-5 items-center'>
                <input
                  id='remember'
                  aria-describedby='remember'
                  name='remember'
                  type='checkbox'
                  className='focus:ring-3 h-4 w-4 rounded border-gray-300 bg-gray-50 focus:ring-primary-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600'
                  required
                />
              </div>
              <div className='ml-3 text-sm'>
                <label
                  htmlFor='remember'
                  className='font-medium text-gray-900 dark:text-white'
                >
                  Remember me
                </label>
              </div>
              <a
                href='#'
                className='ml-auto mt-4 w-full text-left text-sm text-primary-700 hover:underline dark:text-primary-500'
              >
                Lost Password?
              </a>
            </div>
          )}
          <button
            type='submit'
            className='w-full rounded-lg bg-primary-700 px-5 py-3 text-center text-base font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:w-auto'
          >
            Iniciar Sesión
          </button>
          {showRegisterAccount && (
            <div className='text-sm font-medium text-gray-500 dark:text-gray-400'>
              Not registered?{' '}
              <a className='text-primary-700 hover:underline dark:text-primary-500'>
                Create account
              </a>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
